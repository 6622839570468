import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/home.module.css";

const {div, h1, h2, p, b, br, hr, a} = require("hyperscript-helpers")(m);

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            div({class: style.mainview}, [
                h1("Allgemeines"),
                p(["ZaPF steht für ",b("Z"),"usammenkunft ", b("a"),"ller ", b("d"),"eutschsprachigen ",b("P"),"hysikfachschaften und ist die Bundesfachschaftentagung (BuFaTa) der Physik. Sie findest einmal im Semester in einer immer wechselnden Stadt statt, dieses Mal in Mainz. Das Ziel der ZaPF ist ein inhaltlicher und sozialer Austausch zwischen den Unis über die Arbeit in den physikbezogenen Fachschaften, das Studium im Allgemeinen und alle anderen Probleme und Erfolge die Studierende heute beschäftigen.\n" +
                    "Themen die bei der Veranstaltung besprochen werden sind zum Beispiel:\n" +
                    "Austausch über Events und Organisation, Akkreditierung von Studiengängen, Inklusion in Studiengängen, Anti-Diskriminierung, Auswertung von Bachelor-, Master-, sowie Lehramtsstudiengangsumfragen, Digitalisierung und Angebot von Hybridformaten in der Lehre, Vernetzung der Studierenden, Gremienarbeit und andere hochschulpolitische Themen.\n" +
                    "\n" +
                    "Im Laufe der ZaPF werden Resolutionen und Positionspapiere erarbeitet, an relevante Adressaten weitergeleitet und bei Bedarf veröffentlicht.\n" +
                    "Zusätzlich werden alle Ergebnisse der ZaPF im Wiki veröffentlicht."]),
                h1("Meine erste ZaPF"),
                p("Bist Du ein ZaPFi? Wenn Du nicht weißt was das ist, bist Du eins!"),
                p("ZaPFis sind ZaPFika, die zum ersten Mal auf einer ZaPF sind. Das freut uns! Hoffentlich gefällt es Dir auf der Tagung der Enten und Quanten! Damit Du Dich möglichst gut zurechtfindest und einen guten Einstieg in Dein ZaPF-Leben findest, haben wir einige Angebote für Dich:"),
                h2("Mentikon"),
                p("Um Dich auf der Zapf besser zurecht zu finden, kannst du Dir ein Mentikon zuweisen lassen. Mentika sind erfahrene ZaPFika, die Dir während der Tagung beiseite stehen und Fragen beantworten können. Wenn Du Dir ein Mentikon wünscht, gib das einfach in der Anmeldung an."),
                h2("ZaPF Wiki"),
                p("Das Wiki ist die allmächtige und (beinahe) allwissende Arbeitsplattform der ZaPF. Dort findest Du viele gute Infos rund um die ZaPF und Ihre Arbeit. Wir ermutigen alle ZaPFika und Euch als ZaPFis im Besondern sich mit der Tagung im Vorhinein etwas vertraut zu machen:"),
                p("Wörterbuch - Hier findest du nützliche und häufig verwendete Abkürzungen."),
                a("https://zapf.wiki/ZaPFis_Info"),
                p("Geschäftsordnung - Dieser GO wird in den Plenen der ZaPF gefolgt."),
                a("https://zapfev.de/zapf/go/"),
                p("Satzung der ZaPF - Eine klare Definition der ZaPF und ihren Aufgaben"),
                a("https://zapfev.de/zapf/satzung/"),
                p("Kontakt zum StaPF - Eure AnsprechpartnerInnen für alles zwischen den ZaPFen"),
                a("https://zapf.wiki/StAPF"),
                p("Übersicht im ZaPF Wiki - Eine Übersicht über alles Wichtige bei der ZaPF"),
                a("https://zapf.wiki/%C3%9Cbersicht"),
                h1("Die Orga"),])
        );
    }
};
export default page;
